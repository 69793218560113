<template lang="pug">
	section#tabelaUsers
		h2 Base de Clientes

		table
			thead
				td ClientId
				td Nome
				td E-mail
				td CPF/CNPJ
				td Cidade
				td Ação 
			tbody(v-for="customer, index in customers")
				td {{customer.id}}
				td {{customer.name}}
				td {{customer.email}}
				td {{customer.document}}
				td {{customer.city}}
				td
					button(@click="openModal(index)")
						FontAwesomeIcon(:icon="icons.faCircleInfo")
		AsideInfos(:content="filteredCustomer")



</template>

<script>
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import AsideInfos from '@sections/Painel/AsideInfos/AsideInfos.vue'

export default {
	name: "section-tabela-users",
	components: {
		AsideInfos
	},
	data(){
		return {
			icons: {
				faCircleInfo,
			},
			ativo: null,
			customers: []
		}
	},
	computed: {
		filteredCustomer() {
			if(this.customers.length > 0 && this.ativo != null)
				return this.customers[this.ativo].domains
			return {}
		}
	},
	mounted() {
		this.loadCustomers()
	},
	methods: {
		openModal(index) {
			this.ativo = index
			this.$store.commit('toggleAside', 'asideInfos')
		},
		loadCustomers() {
			this.$axios.get('customers/')
				.then(response => {
					this.customers = response.data
				})
		}
	}
}
</script>

<style lang="stylus" scoped src="./TabelaUsers.styl"></style>
