<template lang="pug">
	Aside#asideInfos(:ativo="$store.state.asideInfos")
		.container
			ul
				li(v-for="domain, index in content", :class="{'ativo': ativo == index}" @click="toggleDropdown(index)")
					.head
						h3 {{domain.name}}
						button
							FontAwesomeIcon(:icon="icons.faChevronDown")
					.body
						table
							thead
								td Frases
								td Posição no google
								td Primeira Cobrança
								td Última Cobrança
							tr(v-for="phrase in domain.phrases")
								td {{phrase.phrases}}
								td {{phrase.ranking}}
								td {{phrase.dateStartedBilling ? phrase.dateStartedBilling : 'Não cobrado'}}
								td {{phrase.dateLastBilled ? phrase.dateLastBilled : 'Não cobrado'}}
				
</template>

<script>
import Aside from '@components/Aside/Aside'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'


export default {
	name: "section-aside-infos",
	components: {
		Aside,
	},
	props: {
		content: {
			type: Object
		}
	},
	data() {
		return {
			icons: {
				faChevronDown
			},
			ativo: 0,
		}
	},
	methods: {
		toggleDropdown(index){
			this.ativo = index
		}
	}
}
</script>

<style lang="stylus" scoped src="./AsideInfos.styl"></style>
