<template lang="pug">
	Container
		main#painel
			DadosGerais
			TabelaUsers
</template>

<script>
import Container from '@components/Container/Container'
import DadosGerais from '@sections/Painel/DadosGerais/DadosGerais.vue'
import TabelaUsers from '@sections/Painel/TabelaUsers/TabelaUsers.vue'

export default {
	name: "view-painel",
	metaInfo() {
		return {
			title: 'Visibox | Painel',
			meta: [
				{ name: 'description', content: '' },
			],
		}
	},
	components: {
		Container,
		DadosGerais,
		TabelaUsers
	}
}
</script>

<style lang="stylus" scoped src="./Painel.styl"></style>
