<template lang="pug">
	section#dadosGerais
		h2 Dados Gerais

		ul
			li
				p Clientes totais
				span {{content.total}}
			li
				p Clientes 1º página
				span {{content.pageOne}}
			li
				p Clientes mês
				span {{content.month}}
</template>

<script>
export default {
	name: "section-dados-gerais",
	data() {
		return {
			content: {}
		}
	},
	mounted() {
		this.loadDashboard()
	},
	methods: {
		loadDashboard() {
			this.$axios.get('customers/dashboard')
				.then(response => {
					this.content = response.data
				})
		}
	}
}
</script>

<style lang="stylus" scoped src="./DadosGerais.styl"></style>
