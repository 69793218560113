import { render, staticRenderFns } from "./AsideInfos.vue?vue&type=template&id=07854d90&scoped=true&lang=pug&"
import script from "./AsideInfos.vue?vue&type=script&lang=js&"
export * from "./AsideInfos.vue?vue&type=script&lang=js&"
import style0 from "./AsideInfos.styl?vue&type=style&index=0&id=07854d90&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07854d90",
  null
  
)

export default component.exports